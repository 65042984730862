import { create } from "zustand";

export const useMapStore = create((set) => ({
  zone: {
    id: 0,
    name: "All Zones",
    coords: {
      lat: 44.182205,
      lng: -84.506836,
      Zoom: 9,
    },
  },
  setZone: (zone) => set({ zone }),
  providers: [],
  setProviders: (providers) => set({ providers }),
  providerData: [],
  setProviderData: (providerData) => set({ providerData }),
  appointmentOpen: null,
  setAppointmentOpen: (appointmentOpen) => set({ appointmentOpen }),
  isOpen: null,
  setIsOpen: (isOpen) => set({ isOpen }),
  focusMarker: ({ zone, id, seq }) =>
    set({
      zone: { ...zone, zoom: 10 },
      isOpen: id,
      appointmentOpen: seq,
    }),
  patientSelected: null,
  setPatientSelected: (selected) => set({ patientSelected: selected }),
}));
